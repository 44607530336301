import { Component } from "react";
import { NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { ViberIcon } from "@this-assets/icons/social";
import { COLOR_WHITE } from "@this-basic-styles/colors-const";
import { LogoWhiteIcon } from "@this-assets/icons/ui";
import { FaceboocIcon, InstagramIcon } from '@this-assets/icons/social'
import styles from './footer.module.scss';


class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.props,
    };
  }

  scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -80;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  render() {

    return (
      <footer className="footer">
        <div className="container">
          <div className='row'>
            <div className={`${styles.blockWithBorder} col`}>
              <div className="footer__left">
                <nav className="main-nav">
                  <ul>
                    <li>
                      <HashLink
                        smooth
                        to={"/#about-us"}
                        scroll={(el) => this.scrollWithOffset(el)}
                      >
                        <span className='white bottom-dot-bold-pro'>Про нас</span>
                      </HashLink>
                    </li>
                    <li>
                      <NavLink to="/news">
                        <span className='white bottom-dot-bold-pro'>Новини</span>
                      </NavLink>
                    </li>
                    <li>
                      <a href={process.env.PERSONAL_CABINET} target="_blank">
                        <span className='white bottom-dot-bold-pro'>Особистий кабінет</span>
                      </a>
                    </li>
                    <li>
                      <div className='d-flex'>
                        <div className='pb-5'>
                          <a href="https://chats.viber.com/landinvest/uk" target="_blank">
                            <span className='fs-14-18-b white bottom-dot-bold-pro'>Ми у вайбері</span>
                          </a>
                        </div>
                        <div className='ml-8'>
                          <ViberIcon width={32} height={32} fill={COLOR_WHITE} />
                        </div>
                      </div>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div className={`${styles.blockWithBorder} col`}>
              <div className="d-flex flex-column align-items-center">
                <NavLink
                  to="/"
                  className="mb-15"
                  exact
                  activeClassName="active"
                >
                  <LogoWhiteIcon width={196} height={39} />
                </NavLink>
                <p className='mb-10 bottom-dot-bold-pro'>
                  <NavLink to="/privacy-policy">
                    Політика конфіденційності
                  </NavLink>
                </p>
                <p className='fs-14-18 fw-600 white'>Copyright © {new Date().getFullYear()} LANDINVEST Company S.L. All rights reserved.</p>
                <p className='mt-5 fs-14-18 fw-600 white'>
                  <a href="tel:0800407711">0 800 407711</a>
                  <span> (дзвінки безкоштовні)</span>
                </p>
              </div>
            </div>
            <div className="col">
              <div className="footer__right">
                <div className="social">
                  <div className="d-flex">
                    <a href="https://www.facebook.com/LandInvest.UA" target="_blank">
                      <div className='mr-25'>
                        <FaceboocIcon width={40} height={40} />
                      </div>
                    </a>
                    <a href="https://www.instagram.com/landinvest_ua" target="_blank">
                      <InstagramIcon width={40} height={40} />
                    </a>
                  </div>
                  <p className='mt-10 fs-14-18-b white'>LandInvest у соціальних мережах</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;